import React, { useEffect, useState } from "react"; // Add useState here
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "styled-components";
import Head from "next/head"; // Replaced react-helmet with next/head
import TagManager from "react-server-side-gtm";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
const LayoutContainer = dynamic(() => import("../components/LayoutContainer"));
import Script from "next/script";
import { ModalProvider } from "../context/modalContext";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/index.css";
import "../public/fonts/fonts.css";
import themes from "../constants/theme";

import * as gtag from "../utils/gtag";
import { wrapper } from "../redux/store";
import { ScrollProvider } from "../context/ScrollProvider";
import { HeaderProvider } from "../context/HeaderProvider";
import { LocationProvider } from "../context/LocationContext"; // Import LocationProvider
import useQueryCatcher from "../hooks/useQueryCatcher";

// Progress Bar configuration.
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
// Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const tagManagerArgs = {
  gtmId: 'GTM-M8FSDVV',
  src: 'https://req.classyclosets.com',
  auth: 'uUC2cNJRIDuAgAjGn4MXwA',
  preview: 'env-1',
};

function App({ Component, pageProps }) {
  const [location, setLocation] = useState(null);
  const router = useRouter();
  useQueryCatcher(router);
  const isAdmin = router.asPath.startsWith("/admin");

  useEffect(() => {
    console.log("Initializing TagManager with args:", tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    let visitorIdInterval = setInterval(() => {
      if (window.uniqueVisitorId) {
        console.log("Setting user data in TagManager dataLayer:", {
          user_id: window.uniqueVisitorId,
          fingerprint: window.uniqueVisitorId,
          confidenceScore: window.confidenceScore,
        });
        TagManager.dataLayer({
          dataLayer: {
            user_id: window.uniqueVisitorId,
            fingerprint: window.uniqueVisitorId,
            confidenceScore: window.confidenceScore,
          },
        });
        clearInterval(visitorIdInterval);
      }
    }, 1000);

    return () => clearInterval(visitorIdInterval); // Cleanup
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        console.log("Fetching location data...");
        const response = await fetch('/api/location');
        const data = await response.json();
        console.log("Location data fetched:", data);
        setLocation(data.closestShowroomToUser);
        TagManager.dataLayer({
          dataLayer: {
            country: data.country,
            region: data.region,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
          },
        });
        localStorage.setItem('locationFetchedv1', JSON.stringify(data.closestShowroomToUser));
        localStorage.setItem('closestTenant', JSON.stringify(data.tenantIdLocation));
        localStorage.setItem('usersLocationData', JSON.stringify({ state: data.region, city: data.city }));
        console.log("Location data stored in localStorage");
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const storedLocation = localStorage.getItem('locationFetchedv1');
    if (storedLocation) {
      setLocation(JSON.parse(storedLocation));
      console.log("Location data loaded from localStorage:", JSON.parse(storedLocation));
    } else {
      fetchLocation();
    }
  }, []);

  return (
    <>
      <Script
        id="fingerprint-load"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            console.log("Loading FingerprintJS...");
            const fpPromise = import('https://fpjscdn.net/v3/gn3ZHJpr3KVXlPkeYXmG')
              .then(FingerprintJS => FingerprintJS.load())
              .catch(error => {
                console.error('Error loading FingerprintJS module:', error);
                return null;
              });

            fpPromise
              .then(fp => {
                if (fp) {
                  console.log("FingerprintJS loaded:", fp);
                  return fp.get();
                } else {
                  console.error("FingerprintJS failed to load.");
                  return null;
                }
              })
              .then(result => {
                if (result) {
                  console.log("FingerprintJS result:", result);
                  window.uniqueVisitorId = result.visitorId;
                  window.confidenceScore = result.confidence.score;
                } else {
                  window.uniqueVisitorId = 'null';
                  console.error('FingerprintJS result is null.');
                }
              })
              .catch(error => {
                window.uniqueVisitorId = 'null';
                console.error('Error getting FingerprintJS result:', error);
              });
          `,
        }}
      />
      <CookiesProvider>
        <LayoutContainer>
          <LocationProvider value={location}> {/* Wrap the application with LocationProvider */}
            <ModalProvider>
              <ThemeProvider theme={themes.default}>
                <ScrollProvider>
                  <HeaderProvider>
                    <Component {...pageProps} />
                  </HeaderProvider>
                </ScrollProvider>
              </ThemeProvider>
            </ModalProvider>
          </LocationProvider>
        </LayoutContainer>
      </CookiesProvider>
    </>
  );
}

export default wrapper.withRedux(App);
